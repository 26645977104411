@media only screen and (max-width: 600px) {
    .themaLine .pos-customer .pos-content .product-row .product-container {
        width: 100% !important;
    }

    .themaLine .pos-customer .pos-content .product-row .product-container.normal {
        width: 100% !important;
        height: 150px;
    }

    .center-content{
        text-align: center;
        position: absolute; 
        top: 115px; 
        width: 100%;
    }
}

@media only screen and (min-width: 601px) and (max-width: 900px) {
    .themaLine .pos-customer .pos-content .product-row .product-container {
        width: 50% !important;
        height: 150px;
    }

    .center-content{
        text-align: center;
        position: absolute; 
        left: 33%;
        top: 115px; 
        width: 33%;
    }
}

@media only screen and (min-width: 901px) and (max-width: 1200px) {
    .themaLine .pos-customer .pos-content .product-row .product-container {
        width: 33.3% !important;
        height: 150px;
    }

   
}

@media only screen and (min-width: 901px) and (max-width: 5000px) {
    

    .center-content{
        text-align: center;
        position: absolute; 
        left: 33%;
        top: 115px; 
        width: 33%;
    }
}
    
.themaLine .pos-customer .pos-content .product-row .product-container .product {
    flex-wrap: wrap;
}

.themaLine .pos-customer .pos-content .product-row .product-container {
    width: 25%;
    height: 150px;
}

.themaLine .pos-customer .pos-content .product .img {
    max-height: 150px;
    min-height: 127px;
    width: 30%;
    flex: 30%;
}

.themaLine .pos-customer .pos-content .product .text {
    width: 69%;
    margin-left: auto;
}

.themaLine .pos-customer .pos-content .product .text .price{
    position: absolute;
    bottom: 10px;
    z-index: 100;
}

.gradiente{
    display: block !important;
}

.badge.categories{
    display: none;
}

.themaLine .badge.promocao {
    bottom:0; 
    font-size:0.85em;
    width: 100%;
    left: 0;
    height: 20px;
    top: initial;
}

.gradiente{
    display: none;
}



.home{
    background-color: #fff;

    .banner{
        height: 200px;
        background-position: center;
        background-size: cover;
        width: 100%;
    }
}

