.badge {
	display: inline;
	padding: $badge-padding-y $badge-padding-x;
	vertical-align: middle;

	&.promocao {
		top:0; 
		left:37%; 
		font-size:0.85em;
		border-radius:0 0 5px 5px;
	}
}

