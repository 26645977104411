.distribuidora {
    @media (max-width: 991.98px) {
        .pos-customer .pos-content {
            padding-bottom: 140px;
            padding-top: 0px;
        }

        .pos-customer .pos-content .product-row .product-container {
            padding: 0.703125rem;
            width: 50%;
        }

        .pos-customer .pos-content .product .img {
            min-height: 100px;
            height: 100px;
        }

        .nav-item {
            width: 33% !important;
        }

    }

    @media (min-width: 1200px) {
        .pos-customer .pos-content .product-row .product-container {
            width: 16%;
        }
    }

    .pos-customer .pos-content .product .img {
        min-height: 125px;
    }

    .badge.promocao {
        width: 100%;
        left:0;
    }

    .pos-customer .pos-content .product .text .price {
        color: green;
    }

    .nav-categories .nav-item {
        display: inline-grid;
        place-items: center;
        width: 10%;
    }


    .nav-link{
        text-align: center;
        font-size: 15px;
    }

    .header{
        position: relative;
        text-align: center;
        padding: 20px 20px;
        background: linear-gradient(to bottom, #faa024, rgba(255, 0, 0, 0));


        img{
            width: 70px;
        }
    }
}